/* Center the loader */
#loaderOverlay {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 9;
    opacity: 0.6;
}

#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 99;
    border: 10px solid #eee;
    border-radius: 50%;
    border-top: 10px solid #f7c621;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    transform: translate(-50%, -50%);
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}